<!-- 钟点房设置 -->
<template>
  <section class="cont hourRoom">
    <el-row class="crumbs-box">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>PMS</el-breadcrumb-item>
        <el-breadcrumb-item>住宿设置</el-breadcrumb-item>
        <el-breadcrumb-item>钟点房设置</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row class="content-box">
      <el-alert title="钟点房默认为0.5间夜，如需修改，可点击“设置钟点房间夜量”进行修改。" type="warning" show-icon></el-alert>
      <!-- 主体内容 -->
      <el-row class="table-box">
        <!-- 表格按钮 -->
        <div class="table-head-btn">
          <el-row class="right-btn">
            <el-button @click="nightsDuration"><span>设置钟点房间夜量</span></el-button>
            <el-button class="bg-gradient"  type="primary" @click="addEditEvnet('add')" style="margin-left: 16px;"><span>新增钟点房</span></el-button>
          </el-row>
        </div>
        <!-- 表格 -->
        <el-table :data="tableData" border fit :stripe="true" v-loading="loading">
          <el-table-column prop="roomTypeName" label="房型名称" min-width="30"></el-table-column>
          <el-table-column label="入住时长" min-width="30">
            <template slot-scope="scope">
              {{ scope.row.duration}}小时
            </template>
          </el-table-column>
          <el-table-column label="价格" align="right" min-width="30">
            <template slot-scope="scope">
              ￥{{ scope.row.price}}
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="30">
            <template slot-scope="scope">
              <el-button type="text" @click="addEditEvnet('edit',scope.row)">
                <el-link type="primary">编辑</el-link>
              </el-button>
              <el-button type="text" @click="deleteBt(scope.row)">
                <el-link type="danger">删除</el-link>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <pagination :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging"/>
      </el-row>
    </el-row>
    <!-- 新增/编辑弹窗 -->
    <el-dialog :title="`${addEdit}钟点房`" :visible.sync="show" width="600px" :before-close="handleClose" v-if="show">
      <el-form :model="ruleForm" ref="ruleForm" :rules="rules" class="demo-ruleForm" label-position="right" label-width="120px">
        <el-form-item label="房型名称" prop="roomTypeId">
          <el-select  class="width-300" v-model="ruleForm.roomTypeId" placeholder="请选择房型">
            <el-option v-for="item in roomTypeList" :key="item.id" :label="item.roomTypeName" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="入住时长" prop="duration">
          <el-input class="width-300" v-model="ruleForm.duration" placeholder="请输入" clearable>
            <template slot="append">小时</template>
          </el-input>
        </el-form-item>
        <el-form-item label="价格" prop="price">
          <el-input class="width-300" v-model="ruleForm.price" :onkeyup='dataValidation' placeholder="请输入" clearable>
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">完成</el-button>
      </span>
    </el-dialog>
    <!-- 设置夜量 -->
    <el-dialog title="设置钟点房间夜量" :visible.sync="nightsShow" width="600px" :before-close="nightsClose" v-if="nightsShow">
      <el-alert title="注意：修改后，历史统计数据不受影响" type="warning" :closable="false" show-icon></el-alert>
      <el-form @submit.native.prevent :model="rulenightForm" ref="rulenightForm" :rules="rulenights" class="demo-ruleForm" label-position="right" label-width="120px">
        <el-form-item label="钟点房间夜量基数" prop="hourlyRoomNightVolume" class="nights">
          <el-input-number v-model="rulenightForm.hourlyRoomNightVolume" :precision="1" :step="0.1" :min="0" :max="1"></el-input-number>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="nightsClose">取消</el-button>
        <el-button type="primary" @click="submit('rulenightForm')">完成</el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
import { mapState } from "vuex";
import { pms } from '@/api/interface/pms';
export default {
  data(){
    return {
      hotelId:'',              // 酒店ID
      id:'',                   // 编辑时主键ID
      roomTypeList:[],         // 房型列表
      tableData:[],            // 表格数据
      loading: true,           // 表格加载效果
      ruleForm:{               // 表单数据
        roomTypeId:'',         // 房型ID
        duration:'',           // 入住时长
        price:'',              // 价格
      },
      rules:{                  //校验规则
        roomTypeId:[{ required:true, message: '请选择房型名称' , trigger: 'change'}],
        duration:[{ required:true,message: '请输入入住时长',trigger: 'blur'},],
        price:[{ required:true,message: '请输入价格',trigger: 'blur'},],
      },
      show:false,              // 弹窗显示
      nightsShow:false,        // 夜量弹窗显示
      rulenightForm:{          // 夜量表单
        hourlyRoomNightVolume:0.5,
      },
      rulenights:{
        hourlyRoomNightVolume:[{ required:true,message: '请输入0～1之间的数字',trigger: 'blur'},],
      },
      addEdit:'',              // 弹窗标题
      total: 0,                // 用户列表总条目数
      page: 1,                 // 当前页 默认第一页
      limit:10,                // 每页显示数
      prompt: '提示！',
      del_success: "删除成功！",
      add_success: "添加成功！",
      update_success: '修改成功！',
      confirm_remove: '确定要删除吗？',
    }
  },
  computed: {
    ...mapState(['hotelInfo']),
    // 验证输入金额
    dataValidation(){
      return 'value=value.replace(/[^\\d.]/g,"").replace(".", "$#$").replace(/\\./g, "").replace("$#$", ".").replace(/^(\\-)*(\\d+)\\.(\\d\\d).*$/, "$1$2.$3").replace(/^\\./g, "").replace(/\\.{2,}/g, ".")';
    },
  },
  watch: {
    hotelInfo(newVal, oldVal) {
      if(oldVal && newVal.id !== oldVal.id){
        this.hotelId = this.hotelInfo.id;
        this.getData();
        this.getRoomType();
      }
    }
  },
  mounted(){
    this.hotelId = this.hotelInfo.id;
    this.getData();
    this.getRoomType();
  },
  methods:{
    // 获取房型列表
    getRoomType(){
      const url = pms.getRoomTypeList;
      const param = {
        hotelId:this.hotelId,                            // 酒店ID
        roomTypeSource:'PMS',
      }
      this.$axios.post(url, param, 'json').then((res) => {
        if (res.success) {
          this.roomTypeList = res.records;
        }
      })
    },
    // 获取钟点房列表
    getData(){
      const url = pms.getHourRoom + `?limit=${this.limit}&page=${this.page}`;
      const param = {
        hotelId:this.hotelId,                            // 酒店ID
      }
      this.$axios.post(url, param, 'json').then((res) => {
        if (res.success) {
          this.total = res.records.total;
          this.tableData = res.records.records;
          this.loading = false;
        }
      })
    },
    // 新增房价 / 编辑
    addEditEvnet(state ,item){
      this.show = true;
      if (state === "add") {
        this.addEdit = "新增";
      } else {
        this.addEdit = "编辑";
        this.id = item.id;
        this.ruleForm = {
          roomTypeId:item.roomTypeId,             // 房型ID
          duration:item.duration,                 // 入住时长
          price:item.price,                       // 价格
        }
      }
    },
    // 设置夜量时长
    nightsDuration(){
      this.nightsShow = true;
    },
    // 删除
    deleteBt({id}){
      this.$confirm(this.confirm_remove, this.prompt, {
        confirmButtonText: this.confirm,
        cancelButtonText: this.cancel,
        type: "warning",
      }).then(() => {
        const url = pms.delHourRoom + `${id}`;
        let params = {}
        this.$axios.get(url,params).then((res) => {
          if (res.success) {
            this.$message({showClose: true,message: this.del_success,type: 'success'});
            this.getData();
          }
        })
      });
    },
    // 改变每页数
    pageChange(num) {
      this.limit = num;
      this.getData();
    },
    // 改变当前页
    handlePaging(num) {
      this.page = num;
      this.getData();
    },
    // 重置表单数据
    reset(){
      this.ruleForm = {
        roomTypeId:'',         // 房型ID
        duration:'',           // 入住时长
        price:'',              // 价格
      }
    },
    // 关闭
    handleClose(){
      this.show =false;
      this.reset();
    },
    // 夜量设置关闭
    nightsClose(){
      this.nightsShow = false;
    },
    // 完成
    submitForm(formName){
      this.$refs[formName].validate((valid) => {
        if (valid){
          this.show =false;
          if (this.addEdit === "新增") {
            const url = pms.addHourRoom;
            const param = {
              hotelId:this.hotelId,                            // 酒店ID
              roomTypeId:this.ruleForm.roomTypeId,             // 房型ID
              duration:this.ruleForm.duration,                 // 入住时长
              price:this.ruleForm.price,                       // 价格
            }
            this.$axios.post(url, param, 'json').then((res) => {
              if (res.success) {
                this.$message({showClose: true,message: this.add_success,type: 'success'});
                this.reset();
                this.getData();
              }
            })
          } else {
            const url = pms.editHourRoom;
            const param = {
              id:this.id,                                      // 主键ID
              hotelId:this.hotelId,                            // 酒店ID
              roomTypeId:this.ruleForm.roomTypeId,             // 房型ID
              duration:this.ruleForm.duration,                 // 入住时长
              price:this.ruleForm.price,                       // 价格
            }
            this.$axios.post(url, param, 'json').then((res) => {
              if (res.success) {
                this.$message({showClose: true,message: this.update_success,type: 'success'});
                this.reset();
                this.getData();
              }
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 设置钟点房夜量
    submit(formName){
      this.$refs[formName].validate((valid) => {
        if (valid){
          this.nightsShow =false;
          const url = pms.setHourlyRoomNightVolume;
          const param = {
            id:this.hotelId,                                                 // 酒店ID
            hourlyRoomNightVolume:this.rulenightForm.hourlyRoomNightVolume,  // 夜量基数
          }
          this.$axios.post(url, param, 'json').then((res) => {
            if (res.success) {
              this.$message({showClose: true,message: this.update_success,type: 'success'});
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.hourRoom{
  .el-alert--warning.is-light{
    padding: 10px;
    border: 1px solid #fa0;
    margin-bottom: 20px;
  }
  .nights{
    /deep/ .el-form-item__label{
      width: 140px !important;
    }
    /deep/ .el-form-item__content{
      margin-left: 140px !important;
    }
  }
}

</style>
